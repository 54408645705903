import { connect } from 'react-redux'
import { fetchDictionary } from '../actions'
import TransState from '../TransState';

const mapStateToProps = (state) => {
  return state.localization
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchDictionary: data => {
      dispatch(fetchDictionary(data))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransState)
