import React from 'react'
import TransState from './containers/TransState'
import reducer from './reducer'

const lookup = function(key) {

  if (!this) {
    return ''
  }

  const val = key.split('.').reduce((acc, cur) => acc[cur], this)

  if (val === null) {
    console.warn(`${key} not found in dictionary`, this)

    return key
  }

  return val
}

const buildTranslator = (dict) => {
  return lookup.bind(dict)
}

export { buildTranslator, TransState, reducer }
