import React from 'react'

export default function(props) {
  const className = props.lines === 2
    ? 'error-bubble error-bubble-twoliner'
    : 'error-bubble'

  return (
    <span className={ className }>
      <span className="error-msg">{ props.message }</span>
      <span className="error-arrow"></span>
    </span>
  )
}
