import { connect } from 'react-redux'
import { MozillaButton } from '../../../Components/MozillaButton'

const mapStateToProps = (state, params) => {
  return {
    text: state.localization.dictionary.login.contMozilla
  }
}

const MozillaButtonConnected = connect(
  mapStateToProps
)(MozillaButton)

export {
    MozillaButtonConnected as LoginButton
}
