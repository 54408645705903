import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { QuotesTile } from './quotes.tile'
import './quotes.scss'
import PropTypes from "prop-types";

export class QuotesCarousel extends Component {
    static propTypes = {
        premiumQuotes: PropTypes.array.isRequired
    };

    static defaultProps = {
        premiumQuotes: []
    };

    render() {
        const { list } = this.props
        return (
                <Carousel
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    transitionTime={250}
                    className='quotes-wrapper carousel'>
                    {list.map((item, index) =>
                        <QuotesTile key={index} {...item} />
                    )}
                </Carousel>
        )
    }
}
