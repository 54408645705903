// CardSection.js
import React from 'react';
import {CardElement} from 'react-stripe-elements';
import './CreditCard.scss';

class CreditCard extends React.Component {
  render() {
    return (
        <CardElement style={{base: {fontSize: '18px'}}} />
    );
  }
}

export default CreditCard;
