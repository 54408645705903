import React from 'react'
import PropTypes from 'prop-types'
import Recaptcha from 'react-google-invisible-recaptcha'

const noOnResolved = () => {
  console.warn('No onResolved handler passed to CaptchaForm')
}

export default class CaptchaForm extends React.Component {

  static propTypes = {
    isRequired: PropTypes.bool.isRequired,
    siteKey:    PropTypes.string,
    className:  PropTypes.string,

    // hook to perform an action prior to submitting the form/triggering recaptcha.
    // callback function is passed to continue to recaptcha step.
    beforeSubmit: PropTypes.func,
    onResolved: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isRequired: false,
    beforeSubmit: null
  }

  constructor(props) {
    super(props)

    const {siteKey, isRequired, onResolved} = this.props

    // Setup all captcha conditionals here in a single place.
    if (isRequired) {
      this.submitAction = () => this.recaptcha && this.recaptcha.execute()
      this.recaptchaEle = (
        <Recaptcha
          ref={ ref => this.recaptcha = ref }
          sitekey={ props.siteKey }
          onResolved={ this.onResolved } />
      )
    } else {
      this.recaptchaEle = null
      this.submitAction = onResolved || noOnResolved
    }
  }

  onResolved = () => {
    const response = this.recaptcha.getResponse()

    this.recaptcha.reset()

    this.props.onResolved(response)
  }

  onSubmit = (e) => {
    e.preventDefault()

    if (this.props.beforeSubmit) {
      this.props.beforeSubmit(this.submitAction);
    } else {
      // We may need to trigger a captcha response before submitting the form.
      this.submitAction()
    }
  }

  // TODO: What is going on with onValidUpdate??
  render() {
    return (
      <form
        onSubmit={ this.onSubmit }
        name={ this.props.name }
        className={ this.props.className }>
        { this.props.children }
        { this.recaptchaEle }
      </form>
    )
  }
}
