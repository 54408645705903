import React, { Component } from 'react'

export class QuotesTile extends Component {
  render() {
    const { quote, attr } = this.props

    const quoteParts = quote.split('<QUOTE>')

    return (
      <div className='quote-tile'>
        <div className='quote'>
          {quoteParts[0]}
          <span className='highlight'>{quoteParts[1]}</span>
          {quoteParts[2]}
        </div>
        <div className='attr'>{attr}</div>
      </div>
    )
  }
}
