import React from 'react'

class GoogleLoginButton extends React.Component {
  render() {
    return (
        <a rel="noopener noreferrer" href="/google_signin?s=pocket&t=login" className="login-btn-google">
          <span className="logo"></span>
          <span className="text">{ this.props.text }</span>
        </a>
    )
  }
}

export { GoogleLoginButton }
