import React from 'react'
import PropTypes from 'prop-types'
import { Form as CaptchaForm } from '../../Captcha'
import { Field } from '../../../Components/Form'
import { LoginFormSubmit } from './Content/LoginFormSubmit'
import { AuthPageFormSubmit } from './Content/AuthPageFormSubmit'

export class Form extends React.Component {

  static propTypes = {
    onLogin: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool,
    userDefaults: PropTypes.object,
    dictionary: PropTypes.object.isRequired,
    loginEmail: PropTypes.string,
  }

  static defaultProps = {
    userDefaults: {},
  }

  state = {
    fields: {
      username: {
        isValid: !!this.props.loginEmail,
        isFocused: false,
        isEdited: false,
        value: this.props.loginEmail,
      },
      password: {
        isValid: !(!!this.props.loginEmail),
        isFocused: false,
        isEdited: false,
        value: '',
      },
      // this is passed to login_process as a POST parameter as a way to
      // bypass the feature flag for testing
      __ffbp: {
        isValid: true,
        isFocused: false,
        isEdited: false,
        value: this.featureFlagBypass ? this.featureFlagBypass : ''
      }
    },
  }

  onChange = field => (
    event => {
      const { value } = event.target

      this.setFieldState(field, {
        value:   value,
        isValid: !!value,
      })
    }
  )

  onBlur = field => {
    return () => this.setFieldState(field, {
      isFocused: false,
    })
  }

  onFocus = field => {
    return () => this.setFieldState(field, {
      isFocused: true,
      isEdited: true,
    })
  }

  showError = field => {
    const {
      isValid,
      isFocused,
      isEdited
    } = this.getField(field)

    return !isFocused && !isValid && isEdited;
  }

  /**
   * Captcha resolved, submit form.
   */
  onResolved = response => {

    const values = Object.entries(this.state.fields)
        .reduce((obj, [field, {value}]) => (
          Object.assign(obj, {[field]: value })
        ), {})

    const loginData = {
      ...this.props.userDefaults,
      ...values,
      'g-recaptcha-response': response,
    }

    if (!loginData['route'] && this.props.isAuthPage) {
      loginData['route'] = this.props.authApproveRoute
    }

    this.props.onLogin(loginData)
  }

  getField = field => this.state.fields[field]

  setFieldState = (field, props) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: {
          ...this.getField(field),
          ...props,
        }
      }
    })
  }

  /**
   * Submit button is enabled.
   */
  get isEnabled() {
    return !this.props.isProcessing &&
      (this.isValid || this.isFocused)
  }

  /**
   * All fields are valid.
   */
  get isValid() {
    return Object.entries(this.state.fields)
      .reduce((acc, [key, {isValid}]) => acc && isValid, true);
  }

  /**
   * A field has focus.
   */
  get isFocused() {
    return Object.entries(this.state.fields)
      .reduce((acc, [key, {isFocused}]) => acc || isFocused, false);
  }

  /**
   * Gets the feature flag bypass from the query string
   * if available.
   * @returns {string|null}
   */
  get featureFlagBypass() {
    const urlParams = new URLSearchParams(window.location.search);
    const featureFlagBypass = urlParams.get('__ffbp');
    return featureFlagBypass !== '' ? featureFlagBypass : null;
  }

  render() {
    const { dictionary, isProcessing, isAuthPage } = this.props;
    const formClass = isProcessing
      ? 'loginform-processing'
      : '';
    var changeEmail = isAuthPage ? '/auth/authorize' : '/login'
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('email');
      changeEmail = changeEmail + (urlParams.toString() !== '' ? `?${urlParams.toString()}` : '')

      return (
      <CaptchaForm
        onResolved={ this.onResolved }
        name="loginform"
        className={ formClass }>

        { this.props.loginEmail ?
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              justifyContent: 'space-between',
              height: '2.2em'
            }}>
              <label>{ this.props.loginEmail }</label>
              <a style={ { fontSize: '0.8em', color: '#008078' } }
                 href={ changeEmail }>
                Change email
              </a>
            </div> :
            ''
        }

        <Field
          type={ this.props.loginEmail ? 'hidden' : 'text' }
          autoComplete="username"
          name="username"
          errorMessage={ dictionary.error.username }
          label={ dictionary.login.email }
          value={ this.getField('username').value }
          showError={ false }
          onChange={ this.onChange('username') }
          onBlur={ this.onBlur('username') }
          onFocus={ this.onFocus('username') }
          focusOnMount={ false }
          errorLines={ 2 }
        />

        { !this.props.loginEmail ? '' : <Field
          type="password"
          name="password"
          autoComplete="current-password"
          errorMessage={ dictionary.error.password }
          label={ dictionary.login.password }
          value={ this.getField('password').value }
          showError={ this.showError('password') }
          onChange={ this.onChange('password') }
          onBlur={ this.onBlur('password') }
          onFocus={ this.onFocus('password') }
        /> }

        { isProcessing &&
          <div className="login-processing" /> }

        <div className="loginform-submit">
          {
            this.props.isAuthPage ?
              <AuthPageFormSubmit {...this.props} isEnabled={this.isEnabled} /> :
              <LoginFormSubmit {...this.props} submitButtonText={dictionary.login.submit} isEnabled={this.isEnabled} onClick={this.setLoginClicked}/>
          }
        </div>
      </CaptchaForm>
    );
  }
}
