import React from 'react'
import { connect } from 'react-redux'
import { doLogin } from '../actions'
import { Form as FormComponent } from '../Form'

const mapStateToProps = (state, params) => {
  return {
    ...state.login,
    dictionary: state.localization.dictionary
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLogin: (data) => {
      dispatch(doLogin(data))
    }
  }
}

class Form extends React.Component {
  // Trigger the appropriate events based on the current state.
  componentDidUpdate(prevProps) {
    const { response, error, onSuccess, onError } = this.props
    if (response !== prevProps.response) onSuccess(response)
    if (error !== prevProps.error) onError(error)
  }

  render() {
    return <FormComponent {...this.props} />
  }
}

const FormConnected = connect(mapStateToProps, mapDispatchToProps)(Form)

export { FormConnected as Form }
