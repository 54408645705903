import { connect } from 'react-redux'
import Form from '../Form'

const mapStateToProps = (state) => {
  return state.captcha
}

export default connect(
  mapStateToProps,
)(Form)
