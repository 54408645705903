import React, {createRef} from 'react'

export class MozillaAccountsPrompt extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = createRef();
    this.migrateRef = createRef();
  }

  componentDidMount() {
    snowplow('enableFormTracking')
  }

  render() {
    const { dict, route } = this.props

    const handleUpgrade = () => {
      this.migrateRef.current.value = 'yes'
    }

    const handleDefer = () => {
      this.migrateRef.current.value = 'defer'
    }

    const handleSubmit = () => {
      this.formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      )
    }

    return (
      <div className="fxa-container coreform-container loginform-container">
        <h2 className="text-center">{dict.fxaUpgrade}</h2>
        <p>{dict.fxaSwitchDetailsRebranded}</p>
        <form ref={this.formRef} action="/fxa_upgrade_process" id="fxa-upgrade-form" method="post">
          <input ref={this.migrateRef} type="hidden" name="fxa-migrate" value="defer"/>
          <input type="hidden" name="route" value={route}/>
          <button className="primary" onClick={handleUpgrade}>{dict.fxaUpgrade}</button>
          <button className="secondary" onClick={handleDefer}>{dict.fxaLearnMore}</button>
        </form>
        <div className="fxa-divider" />
      </div>
    )
  }
}
