import fetch from 'cross-fetch'
import stickyLink from './stickyLink'

const csrfToken = function() {
  return window.__STATE__.csrf.token
}

const buildData = (data) => {
  return Object.assign({
    form_check : csrfToken(),
    is_ajax    : 1,
  }, data)
}

const getFormData = (data) => {
  let formData = new FormData()

  for (let [key, value] of Object.entries(data)) {
    formData.append(key, value)
  }

  return formData
}

class APIError extends Error {
  constructor(id, url, ...params) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError)
    }

    // server error code
    this.id = id
    this.url = url
  }

  toState = () => {
    const { id, url, message } = this

    return {
      id,
      url,
      message,
    }
  }
}

const post = function(url, postData) {
  const data = getFormData(buildData({
    ...postData,
    form_check: csrfToken(),
  }))

  const throwDefault = (e) => {
    throw new Error('Unknown API error occurred')
  }

  return fetch(url, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      'Accept': 'application/json'
    },
    body: data,
  })
  .then(response => response.json())
  .catch(throwDefault)
  .then(data => {
    data || throwDefault()

    const {
      error,        // error integer id
      locationUrl,  // redirect target url
      error_msg,    // human friendly
      status,       // 1 = success
    } = data

    if (status !== 1) {
      throw new APIError(error, locationUrl, error_msg);
    }

    return data
  })
}

export {stickyLink, csrfToken, post}
