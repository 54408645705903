import React, { useState } from 'react'
import { connect } from 'react-redux'
import { LoginPage as LoginPageComponent } from '../LoginPage'
import { MozillaAccountsPrompt } from '../MozillaAccountsPrompt'
import { Form } from '../../AuthNative'
import { LoginButton as GoogleButton } from '../../AuthGoogle'
import { LoginButton as MozillaButton } from '../../AuthMozilla'
import { LoginButton as AppleButton } from '../../AuthApple'

const getErrorMessage = (state) => {
  const error = state.login.error

  return error ? error.message : state.login.errorHtml
}

const mapStateToProps = (state, params) => {
  const dictionary = state.localization.dictionary
  const dict = {...dictionary.login, ...dictionary.auth};
  const errorMsg = getErrorMessage(state)
  const message = state.login.messageHtml

  return {
    dict,
    errorMsg,
    message
  }
}

const onError = (error, user) => {
  // We used to redirect on error to /login.
  // However, this login page is now always included where it is, so no need to redirect a user
}

/**
 * Gets user email from the query string
 * @returns {string | undefined}
 */
const getInitialEmail = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('email')?.trim()
}



/**
 * Checks if the page that loaded this component is /auth/authorize
 * @returns {boolean}
 */
const isAuthPage = () => {
  return !!document.querySelector('#login-page-login-container.auth_page')
}

/**
 * Get data attribute by name if the auth page rendered this component
 * @param name
 * @returns {*|string}
 */
const getAuthPageDataAttribute = (name) => {
  const loginContainer = document.querySelector('#login-page-login-container.auth_page')
  return loginContainer ? loginContainer.dataset[name] : ''
}

/**
 * Get the auth approve route to /auth/approve_access
 * @returns {*|string}
 */
const getAuthApproveRoute = () => {
  return getAuthPageDataAttribute('approveRoute')
}

/**
 * Get the auth route to /auth/authorize
 * @returns {*|string}
 */
const getAuthRoute = () => {
  return getAuthPageDataAttribute('authRoute')
}

const LoginPage = (props) => {
  const initialEmail = getInitialEmail()
  const isAuthLogin = isAuthPage()
  const authRoute = getAuthRoute()
  const authApproveRoute = getAuthApproveRoute()

  const [loginEmail, setLoginEmail] = useState(initialEmail)
  const [mozillaAccountsPrompt, setMozillaAccountsPrompt] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState(null)

  const onSuccess = ({
    email: validEmail,
    logInEmail,
    locationUrl,
    fxaValid
  }) => {
    // Set redirectUrl so we can pass it to mozillaAccountsPrompt and do the
    // redirect accordingly
    setRedirectUrl(locationUrl)

    //If we have a valid fxa we will redirect them to fxa auth immediately
    if (fxaValid) return window.location.assign(locationUrl)

    // If we have a log in email, the user is authorizing an email without fxa
    if (logInEmail) return setLoginEmail(logInEmail)

    // If we get a valid email, we have checked the passed in email address
    // and confirmed they are still using Pocket native auth, so should be shown
    // the modal prompting them to transition their account
    if (validEmail) return setMozillaAccountsPrompt(true)

    // This is the default behavior
    window.location.assign(locationUrl)
  }

  return mozillaAccountsPrompt ? (
    <MozillaAccountsPrompt dict={props.dict} route={redirectUrl} />
  ) : (
    <LoginPageComponent
      dict={props.dict}
      messageHtml={props.message}
      errorHtml={props.errorMsg}
      isAuthPage={isAuthLogin}
      authRoute={authRoute}
      top={
          <div className="login-button-wrapper">
              <MozillaButton />
              <AppleButton />
              <GoogleButton />
          </div>
      }
      bottom={
          <Form
              loginEmail={loginEmail || ''}
              isAuthPage={isAuthLogin}
              authApproveRoute={authApproveRoute}
              onError={onError}
              onSuccess={onSuccess}
          />
      }
    />
  )
}

const LoginPageConnected = connect(mapStateToProps)(LoginPage)

export { LoginPageConnected as LoginPage }
