import './style.js'
import 'babel-polyfill'
import 'es6-map/implement'
import 'url-search-params-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import '../Components/InterUI';
import './Components/Header';
import './Modules/Homepage';
import './Modules/FirefoxLearnMore';
import './Components/HomePage'

import { TransState } from './Modules/Localization'
import { LoginPage } from './Modules/LoginPage'
import { UpdatePaymentMethodForm } from './Components/UpdatePaymentMethodForm'
import store from './store'

import SentryBootstrapper from './sentry';

SentryBootstrapper();

import QuotesCarousel from "../premium/Modules/Quotes/containers/quotes.carousel";

const apps = [
  {
    id: 'login-page-login-container',
    element: <LoginPage />,
  },
  {
    id: 'update-payment-method-container',
    element: <UpdatePaymentMethodForm />,
  },
  {
    id: 'premium-quotes',
    element: <QuotesCarousel />,
  }
];

const loadApps = () => {
  apps.forEach(({id, element}) => {
    const el = document.getElementById(id);

    if (el) {
      ReactDOM.render(
        <Provider store={store}>
          <TransState>{ element }</TransState>
        </Provider>
        , el
      )
    }
  })
};

document.addEventListener('DOMContentLoaded', loadApps);
