import React from 'react'
import { wrapTranslation } from '../../../util/wrapTranslation'
import PropTypes from 'prop-types'
import { stickyLink } from '../../../util'
import { ErrorMsg } from '../../../Components/Form'

export class LoginPage extends React.Component {

  static propTypes = {
    messageHtml: PropTypes.string,
    errorHtml:   PropTypes.string,
    top:         PropTypes.object.isRequired,
    bottom:      PropTypes.object.isRequired,

    /**
     * location: Optional value to be passed used for constructing links where
     * querystring params are passed from the location to new urls.
     */
    location: PropTypes.string,

    dict: PropTypes.shape({
      headline: PropTypes.string,
      or:       PropTypes.string.isRequired,
      forgot:   PropTypes.string.isRequired,
      signup:   PropTypes.string.isRequired,
      noaccount: PropTypes.string.isRequired,
    })
  }

  static defaultProps = {
    formIsVisible: true,
    location:      window.location.search,
  }

  getClassName = () => {
    let className = 'coreform-container loginform-container'.split(' ')

    // TODO: Update css to not require these properties. We use these extra
    // classes to alter the element height when internal form is in a minimized
    // state. :(
    this.props.formIsVisible && className.push('loginform-formstate')
    this.props.errorHtml && className.push('loginform-containererror')

    return className.join(' ');
  }

  render() {
    const className = this.getClassName(this.state)

    const {
      location,
      messageHtml,
      errorHtml,
      top,
      bottom,
      dict,
      isAuthPage,
      authRoute,
    } = this.props

    const privacy = wrapTranslation(dict.agreePocket, [
      { a: { href: '/tos', key: 1  } },
      { a: { href: '/privacy', key: 2 } }
    ]
    )
    const ffPrivacy = wrapTranslation(dict.agreeMozilla, [
      { a: { href: 'https://www.mozilla.org/about/legal/terms/services/', key: 1 } },
      { a: { href: 'https://www.mozilla.org/privacy/mozilla-accounts/', key: 2  }}
    ])

    return (
      <React.Fragment>
        <div className={ className }>
          { isAuthPage ? <h3>{dict.loginAuthorize}</h3> : <h2>{dict.headline}</h2> }

          { top }

          <div className="signup-ordivider">{ dict.or }</div>

            { messageHtml &&
                <ErrorMsg className="login-msg">{ messageHtml }</ErrorMsg> }

            { errorHtml &&
                <ErrorMsg className="login-error">{ errorHtml }</ErrorMsg> }

          { bottom }

            <p className="login-footer">
                {errorHtml &&
                    <p className="forgot-p">
                        <a className="forgot" href={stickyLink('/forgot', {location})}>{dict.forgot}</a>
                    </p>}
                <b>{dict.noaccount} </b>
                 <a className="signup" href={isAuthPage ? ('/ff_signin?route=' + authRoute) : stickyLink('/ff_signin', {location})}>
                    {dict.signup}
                </a> <span className="rarrow">›</span>
            </p>

            <div className="signup-disclaimer for-login">
            {dict.signUpAgree} <br/>
            {privacy} <br/>
            {ffPrivacy} <br/>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
