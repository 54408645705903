import React from 'react';
import PropTypes from 'prop-types';

export default class TransState extends React.Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    fetchDictionary: PropTypes.func.isRequired,
  }

  fetchDictionary = () => {
    this.props.fetchDictionary(this.props.locale)
  }

  UNSAFE_componentWillMount() {
    if (this.props.locale) {
      this.fetchDictionary()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.fetchDictionary()
    }
  }

  render() {
    return (this.props.children)
  }
}
