import _ from 'lodash/core'
import URLSearchParams from 'url-search-params'

const getQueryObj = url => {
  if (url.indexOf('?') === -1) {
    return {}
  }

  const query = url.split('?').pop()
  const cur = new URLSearchParams(query)

  return [...cur.keys()].reduce((sum, val) => {
     return {
       ...sum,
       [val]: cur.get(val)
     };
   }, {});
}

const queryString = params => {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .sort()
    .join('&')
}

export default (url, {
  location=window.location.search,
  pick=null
}) => {
  const cur = new getQueryObj(location)
  const target = new getQueryObj(url)

  pick = pick || _.keys(cur)

  const preserve = _.pick(cur, pick)
  const query = queryString({...preserve, ...target})
  const prefix = url.split('?').shift()

  return _([
    prefix,
    query
  ])
  .compact()
  .join('?')
}
