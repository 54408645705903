import React from 'react'
import PropTypes from 'prop-types'
import ErrorBubble from '../ErrorBubble'

export default class Field extends React.Component {

  static propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    autoComplete: PropTypes.string,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    showError: PropTypes.bool,
    focusOnMount: PropTypes.bool,
    // TODO: Find a way to remove errorLines property. UI should know how much
    // space text requires.
    errorLines: PropTypes.number,
  }

  static defaultProps = {
    focusOnMount: false,
    errorLines: 1,
  }

  static _id = 0;

  static get nextId() {
    return this._id++;
  }

  get inputId() {
    this.id = this.id || this.constructor.nextId;

    return `field-${this.id}`;
  }

  get className() {
    return this.props.showError
      ? 'form-field form-field-error'
      : 'form-field';
  }

  componentDidMount() {
    this.props.focusOnMount && this.input && this.input.focus()
  }

  render() {
    const {
      type,
      name,
      label,
      autoComplete,
      errorMessage,
      value,
      onFocus,
      onBlur,
      onChange,
      showError,
      errorLines,
    } = this.props

    return (
      <div className={ this.className } >
        {showError && <ErrorBubble lines={ errorLines } message={errorMessage} />}
        <label htmlFor={ this.inputId }>{ label }</label>
        <input
          id={ this.inputId }
          type={ type }
          name={ name }
          value={ value }
          placeholder={ label }
          onFocus={ onFocus }
          onBlur={ onBlur }
          onChange={ onChange }
          autoComplete={ autoComplete }
          ref={input => { this.input = input }}
        />
      </div>
    )
  }
}
