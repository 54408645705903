import './style.css';
import './product_mockup.png';
import './product_mockup2x.png';

/*
The hidden react google signup button on the page when the #google-signup button is clicked.
TODD: Create a react google button styled for lacroix homepage.
 */
const setupLaCroixGoogleButton = () => {
  const buttonEl = document.getElementById('google-signup');

  if (!buttonEl) {
    return;
  }

  buttonEl.addEventListener('click', () => {
    document
      .querySelector('#signup-page-google-button button')
      .click();
  });
}

document.addEventListener('DOMContentLoaded', setupLaCroixGoogleButton, false);
