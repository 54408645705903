import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducer from './reducer'

const isDev = window.__STATE__.env === 'dev';

let middleware = [reducer];

if (isDev && window.__REDUX_DEVTOOLS_EXTENSION__) {
  middleware.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

middleware.push(applyMiddleware(thunkMiddleware));

export default createStore(...middleware);
