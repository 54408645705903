import React from 'react'

/**
 * Error messages delivered from production may have links or other markup in
 * them.
 */
export default function(props) {
  return props.children && (
    <p className={ props.className } dangerouslySetInnerHTML={ {__html: props.children} } />
  )
}
