import React from 'react';
import {StripeProvider} from 'react-stripe-elements';
import {Elements} from 'react-stripe-elements';
import UpdatePaymentMethodActualForm from "./UpdatePaymentMethodActualForm";
import PropTypes from "prop-types";

class UpdatePaymentMethodForm extends React.Component {

  static propTypes = {
    stripe: PropTypes.shape({
      publicKey: PropTypes.string.isRequired,
    }),
    csrf: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }),
    dict: PropTypes.shape({
      details:     PropTypes.string,
      instruction: PropTypes.string.isRequired,
      paymentAuthorization:   PropTypes.string.isRequired,
      update: PropTypes.string.isRequired,
      processing: PropTypes.string.isRequired,
    })
  };

  static defaultProps = {
    stripe: {
      publicKey: 'pk_test',
    },
    csrf: {
      token : 'replaceme'
    }
  };

  constructor(props) {
    super(props);
    this.state = {stripe: null};
  }
  componentDidMount() {
      if (window.Stripe) {
          this.setState({stripe: window.Stripe(this.props.stripe.publicKey)});
      } else {
          document.querySelector('#stripe-js').addEventListener('load', () => {
              // Create Stripe instance once Stripe.js loads
              this.setState({stripe: window.Stripe(this.props.stripe.publicKey)});
          });
      }
  }
  render() {

    // this.state.stripe will either be null or a Stripe instance
    // depending on whether Stripe.js has loaded.
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <UpdatePaymentMethodActualForm  csrf={ this.props.csrf } dict={ this.props.dict }/>
        </Elements>
      </StripeProvider>
    );
  }
}

export default UpdatePaymentMethodForm
