import React from 'react'
import { connect } from 'react-redux'
import { GoogleLoginButton } from '../../../Components/GoogleLoginButton';

const mapStateToProps = (state, params) => {
  return {
    text:         state.localization.dictionary.login.contGoogle
  }
}

const AuthGoogleLoginButtonConnected = connect(
  mapStateToProps,
)(GoogleLoginButton)


export {
  AuthGoogleLoginButtonConnected as LoginButton
}
