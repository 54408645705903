import React from 'react'
import './MozillaButton.scss'

const MozillaButton = function(props) {
  return (
      <a rel="noopener noreferrer" href="/ff_signin?s=pocket&t=login" className="login-btn-mozilla">
          <span className="text">{props.text}</span>
      </a>
  )
}

export {MozillaButton}
