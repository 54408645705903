import React from 'react'
//https://codesandbox.io/s/optimistic-monad-fvlrz?file=/src/App.js

export const wrapTranslation = (message, interpolations) => {
  const messageArray = message.split('*')
  const convertedMessage = messageArray.map((currentMessage, index) => {
    return index % 2 === 1
      ? interpolate(currentMessage, interpolations.shift())
      : currentMessage
  })
  return React.createElement(React.Fragment, {}, convertedMessage)
}

function interpolate(stringToWrap, currentInterpolation) {
  if (!currentInterpolation) return stringToWrap

  const wrappers = Object.keys(currentInterpolation)
  const adjusted = wrappers.reduce((previousChild, wrapType) => {
    return React.createElement(
      wrapType,
      currentInterpolation[wrapType],
      previousChild
    )
  }, stringToWrap)

  return adjusted
}
