import { connect } from 'react-redux'
import { AppleLoginButton } from '../../../Components/AppleLoginButton'

const mapStateToProps = (state, params) => {
  return {
      text: state.localization.dictionary.login.contApple
  }
}

const AppleButtonConnected = connect(
  mapStateToProps
)(AppleLoginButton)

export {
  AppleButtonConnected as LoginButton
}
