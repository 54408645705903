import { connect } from 'react-redux'
import UpdatePaymentMethodForm from '../UpdatePaymentMethodForm'

const mapStateToProps = (state) => {
  return {
    stripe: state.stripe,
    csrf: state.csrf,
    dict: state.localization.dictionary.premiumSettings
  }
};

export default connect(
  mapStateToProps,
)(UpdatePaymentMethodForm)
