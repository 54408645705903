import React from 'react'
import PropTypes from 'prop-types'
import {LoginFormSubmit} from '../LoginFormSubmit'

export class AuthPageFormSubmit extends React.Component {

  static propTypes = {
    dictionary: PropTypes.object.isRequired,
    isEnabled: PropTypes.bool,
  }

  render() {
    const {dictionary, isEnabled} = this.props;

    return (
        <React.Fragment>
          <LoginFormSubmit {...this.props} submitButtonText={dictionary.cta.authorize} isEnabled={isEnabled}/>
        </React.Fragment>
    );
  }
}
