import React from 'react'

class AppleLoginButton extends React.Component {

  render() {
      return (
          <a rel="noopener noreferrer" href="/apple_signin?s=pocket&t=login" className="login-btn-apple">
              <span className="logo"></span>
              <span className="text">{ this.props.text }</span>
          </a>
      )
  }
}

export { AppleLoginButton }
