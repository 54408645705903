import { post } from '../../util'

export const LOGIN_START = 'LOGIN_START'
function loginStart() {
  return {
    type: LOGIN_START,
  }
}

export const LOGIN_ERROR = 'LOGIN_ERROR'
function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error: error.toState(),
  }
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
function loginSuccess(response) {
  return {
    type: LOGIN_SUCCESS,
    response,
  }
}

export const doLogin = (user) => {
  return dispatch => {
    dispatch(loginStart())

    return post('/login_process.php', {
        source_page: document.location.pathname,
        feed_id:     user.username, // rename username to feedid for PHP
        ...user,
      })
      .then(r => dispatch(loginSuccess(r)))
      .catch(e => dispatch(loginError(e)))
  }
}
