import { LOGIN_START, LOGIN_ERROR, LOGIN_SUCCESS } from './actions'

const initialState = window.__STATE__.login || {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        isProcessing: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        response: action.response
      }
    case LOGIN_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: action.error
      }
    default:
      return state
  }
}
