import React from "react";
import CreditCard from '../CreditCard/CreditCard';
import {injectStripe} from 'react-stripe-elements';
import PropTypes from "prop-types";

class UpdatePaymentMethodActualForm extends React.Component {

    state = {
        formProcessing: false
    };

  static propTypes = {
    dict: PropTypes.shape({
      details:     PropTypes.string,
      instruction: PropTypes.string.isRequired,
      paymentAuthorization:   PropTypes.string.isRequired,
      update: PropTypes.string.isRequired,
      processing: PropTypes.string.isRequired,
    })
  }

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.setFormProcessing(true);
    this.props.stripe.createToken().then(({token}) => {
      if (token === undefined) {
        Sentry.captureMessage('There was an error receiving a new payment method token from stripe.');
      }

      this.submitForm(token);
    }).catch((error) => {
        this.setFormProcessing(false);
    });
  };

  submitForm = (stripeToken) => {
    fetch('/premium/payment_method', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stripeToken: stripeToken.id,
        _token: this.props.csrf.token
      }),
    }).then((response) => {
      response.json().then((json) => {
        var redirectUrl = json.url;
        if (redirectUrl !== undefined) {
          window.location = redirectUrl;
        } else {
          Sentry.captureMessage('There was an error updating a users payment method');
        }
        this.setFormProcessing(false);
      });
    });
  };

  setFormProcessing = (processing) => {
      this.setState({
          formProcessing: processing
      });
  };

  render() {
    const {
      dict,
    } = this.props

    return (
      <form onSubmit={this.handleSubmit}>
        <h4>{ dict.details }</h4>
        <p>{ dict.instruction }</p>
        <div className="form-row">
          <CreditCard />
        </div>
        <br />
        <p>{ dict.paymentAuthorization }</p>
        <input type="hidden" name="_token" value={ this.props.csrf.token } />
        <div hidden={!this.state.formProcessing}>{ dict.processing }</div>
        <button disabled={this.state.formProcessing} type="submit" tabIndex="11" className="btn btn-purchase-premium1">{ dict.update }</button>
      </form>
    );
  }
}

//Form content needs to be a separate file so we can export it in a wrapper function.
export default injectStripe(UpdatePaymentMethodActualForm);
