import fetch from 'cross-fetch'

export const DICTIONARY_GET = 'DICTIONARY_GET'
export const DICTIONARY_SET = 'DICTIONARY_SET'

function getDictionary() {
  return { type: DICTIONARY_GET }
}

function setDictionary(dictionary) {
  return {
    type:       DICTIONARY_SET,
    dictionary: dictionary,
  }
}

export function fetchDictionary(locale) {
  const url = `/localization/dict-${locale}.min.json`

  return (dispatch) => {
    dispatch(getDictionary())

    return fetch(url, {
      // Some environments require http auth in order to access localization
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .then(json => dispatch(setDictionary(json)))
  }
}
