import { connect } from 'react-redux'
import {QuotesCarousel} from "../quotes.carousel";

const mapStateToProps = (state) => {
    return {
        list: state.premiumQuotes,
    }
};

export default connect(
    mapStateToProps,
)(QuotesCarousel)
