import _get from 'lodash/get'
import { DICTIONARY_SET } from './actions'

// Filter for the dictionary items that we require.
// Map the terms to keys which make the most sense for the web app.
const transform = (dict) => {
  const key = (path) => _get(dict, path, '')

  return {
    error: {
      username: key('error.enteremailusername'),
      password: key('error.invalidpassword'),
    },
    auth: {
      loginAuthorize: key('auth.loginauthorize'),
    },
    cta: {
      authorize: key('cta.authorize'),
      noThanks: key('cta.nothanks'),
    },
    login: {
      signUpAgree: key('login.signUpAgree'),
      agreePocket: key('login.agreePocket'),
      agreeFirefox: key('login.agreeFirefox'),
      agreeMozilla: key('login.agreeMozilla'),
      headline:     key('login.login'),
      loginFirefox: key('login.loginfirefox'),
      loginGoogle: key('login.logingoogle'),
      or: key('login.or'),
      email: key('login.email'),
      username: key('login.emailusername'),
      password: key('login.password'),
      submit: key('login.login'),
      forgot: key('login.forgotuserpass'),
      signup: key('login.signupnow'),
      noaccount: key('login.noaccount2'),
      contMozilla: key('learnmorev2.continuemozilla'),
      contGoogle: key('learnmorev2.continuegoogle'),
      contApple: key('learnmorev2.continueapple'),
      fxaCalltoaction: key('fxaupgrade.calltoaction'),
      fxaWannaproceed: key('fxaupgrade.wannaproceed'),
      fxaYeslabel: key('fxaupgrade.yeslabel'),
      fxaYestext: key('fxaupgrade.yestext'),
      fxaNolabel: key('fxaupgrade.nolabel'),
      fxaNotext: key('fxaupgrade.notext'),
      fxaConfirm: key('fxaupgrade.confirm'),
      fxaSwitchTo: key('fxaupgrade.switchtofxa'),
      fxaSwitchDetails: key('fxaupgrade.switchdetails'),
      fxaSwitchDetailsRebranded: key('fxaupgrade.switchdetailsrebranded'),
      fxaUpgrade: key('fxaupgrade.upgrade'),
      fxaUpgradeNotYet: key('fxaupgrade.upgradenotyet'),
      fxaLearnMore: key('login.fxalearnmore'),
      fxaUpgradeRequirement: key('fxaupgrade.upgraderequirement')
    },
    premium: {
      toUpgradePlease: key('premium.toupgradeplease'),
    },
    premiumSettings: {
      details: key('account_premiumsettings.updatepaymentdetails'),
      instruction: key('account_premiumsettings.updatepaymentinstruction'),
      paymentAuthorization: key('account_premiumsettings.updatepaymentauthorization'),
      update: key('cta.update'),
      processing: key('premium_checkout.form.button.processing'),
    }
  }
}

const initialState = {
  ...window.__STATE__.localization,
  dictionary: transform({})
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DICTIONARY_SET:
      return {
        ...state,
        dictionary: transform(action.dictionary),
      }
    default:
      return state
  }
}
