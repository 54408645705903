import { combineReducers } from 'redux'

// TODO: Invert control
import { reducer as loginReducer } from './Modules/AuthNative'
import { reducer as localizationReducer } from './Modules/Localization'
import { reducer as captchaReducer } from './Modules/Captcha'
import { reducer as stripeReducer } from './Modules/Stripe'
import { reducer as csrfReducer } from './Modules/CSRF'
import { reducer as premiumQuotesReducer } from './Modules/PremiumQuotes'

export default combineReducers({
  login:         loginReducer,
  localization:  localizationReducer,
  captcha:       captchaReducer,
  stripe:        stripeReducer,
  csrf:          csrfReducer,
  premiumQuotes: premiumQuotesReducer
});
