import React from 'react'
import PropTypes from 'prop-types'

export class LoginFormSubmit extends React.Component {

  static propTypes = {
    loginEmail: PropTypes.string,
    submitButtonText: PropTypes.string,
    isEnabled: PropTypes.bool,
  }

  render() {
    const {
      isProcessing,
      loginEmail,
      submitButtonText,
      isEnabled
    } = this.props;

    const buttonClass = isProcessing
        ? 'login-btn-email btn-disabled'
        : 'login-btn-email';

    return (
        <input type="submit"
               value={!loginEmail ? 'Next' : submitButtonText}
               className={buttonClass}
               disabled={!isEnabled}/>
    );
  }
}
